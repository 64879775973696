@import "variables.scss";

.rb-color-primary {
  color: $primaryColor !important;
}

.rb-bg-primary {
  background-color: $primaryColor !important;
}

.rb-nav-link {
  color: $primaryColor !important;
}

.rb-active-nav-link {
  color: #ffffff !important;
  background-color: $primaryColor !important;
}

.rb-active-nav-davygray-link {
  color: $blueColor !important;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.primary-toggle-btn.active {
  color: #ffffff !important;
  background-color: $primaryColor !important;
  border-color: $primaryColor !important;
}

#user-drop-down {
  display: flex;
  align-items: center;
}

.primary-btn {
  background-color: $primaryColor;
  border: none;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
}

.primary-toggle-btn.active {
  color: #ffffff !important;
  background-color: $primaryColor !important;
  border-color: $primaryColor !important;
}

.secondary-btn {
  color: #ffffff;
  background-color: $secondaryColor;
  border: none;
}

.cancel-btn {
  background-color: $davyGreyColor;
  border: none;
}

.submit-btn {
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  background-color: $actionGreen;
  border: none;
}

.error-btn {
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  background-color: $davyGreyColor;
  border: none;
}

.thirdparty-deny-btn {
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  border: none;
}

.btn-facebook {
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  background-color: #3c5a99;
  border: none;
}

#upload-btn {
  margin-right: 1rem;
  background-color: $logbookOrange;
  border: none;
  border-radius: 20px;
}

#upload-btn:hover {
  opacity: 0.8;
}

#upgrade-btn {
  color: #ffffff;
  background-color: $actionGreen;
  border: none;
  border-radius: 20px;
}

#upgrade-btn:hover {
  opacity: 0.8;
}

#dashboard-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

input {
  padding: 0.5rem;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

.input-feedback {
  color: $errorColor;
  margin-top: 0.25rem;
}

.modal-backdrop {
  opacity: 0.6 !important;
}

.modal-content {
  border: none;
  box-shadow: $cardShadow;
}

.error-modal-body {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.navigation-bar {
  background-color: #ffffff;
  box-shadow: $cardShadow;
  padding: 0.5rem 0rem;
}

.user-avatar {
  display: block;
  margin: auto;
  border-radius: 50%;
}

.upload-avatar {
  display: block;
  width: 6rem;
  height: 6rem;
  object-fit: cover;
  margin: auto;
  margin-bottom: 1rem;
  border-radius: 50%;
}

.subscription-toggle-btn {
  width: 100%;
  color: #ffffff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.subscription-toggle-btn.active,
.subscription-toggle-btn:hover {
  font-weight: bold;
  color: #000000 !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.card {
  border: none;
  box-shadow: $cardShadow;
}

#event-list {
  height: 100%;
  overflow-y: auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

#event-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: $secondaryColor;
}

#event-list::-webkit-scrollbar {
  width: 8px;
  background-color: $secondaryColor;
}

#event-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $primaryColor;
}

.event-date-row {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: $secondaryColor;
}

.event-date {
  padding: 0.25rem 1rem !important;
  font-weight: bold;
  color: $primaryColor;
}

.event-icon {
  padding: 0 1rem !important;
  color: $text;
  text-align: center;
  font-size: 1.5rem;
  vertical-align: middle !important;
}

.event-title {
  color: $text;
  font-weight: bold;
}

.event-subtitle {
  color: $text;
  font-size: 0.8rem;
}

.event-time {
  padding-right: 1.5rem !important;
  text-align: end;
  vertical-align: middle !important;
  color: $text;
  white-space: nowrap;
}

.old-event {
  color: $textMuted;
}

.flight-map {
  height: 100%;
  overflow: hidden;
  box-shadow: $cardShadow;
}

.component-boundary {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-stats-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.dashboard-stats-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}

#event-date-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: $primaryColor;
  border-color: $primaryColor;
  box-shadow: none;
}

.date-picker-title {
  color: $primaryColor;
}

.Range .DayPicker-Day {
  border-radius: 0 !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: $primaryColor;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: $primaryColor;
}

.Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: $secondaryColor;
}

.tooltip-inner {
  background-color: $davyGreyColor;
}

.tooltip .arrow::before {
  border-left-color: $davyGreyColor;
}

.settings-row-label {
  text-align: right;
  margin-bottom: 0.875rem;
  font-weight: bold;
}

[data-braintree-id="american-express-card-icon"] {
  display: none !important;
}

.button-focus-color {
  &:focus {
    color: #fff;
    background-color: $primaryColor;
    border-color: $primaryColor;
  }
}

.stats-card-content-color {
  color: $text !important;
}

@media (min-width: 1200px) {
  .dashboard-stats-content {
    min-height: 116px;
  }
}

@media (max-width: 767.98px) {
  #dashboard-content {
    height: auto;
  }

  #event-list {
    max-height: 500px;
  }

  .flight-map {
    height: 500px;
  }

  #events-map-row {
    flex-direction: column-reverse;
  }

  #flight-map-container {
    margin-bottom: 0;
  }

  .settings-row-label {
    text-align: left;
  }

  #error-page-title {
    font-size: 3rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .stats-card-content {
    font-size: 1rem !important;
  }
}

@media (max-width: 575.98px) {
  #event-range-picker-dates {
    display: block;
  }

  #event-range-picker-from {
    width: 4rem;
    border-bottom-left-radius: 0;
  }

  #event-range-picker-to {
    width: 4rem;
    border-left: 1px solid #ced4da !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .stats-card-content {
    font-size: 1rem !important;
  }
}
