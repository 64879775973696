$primaryColor: #049fd9;
$secondaryColor: #c4d6ed;
$davyGreyColor: #58585b;
$logbookOrange: #ff7300;
$actionGreen: #6cc04a;
$blueColor: #1a2182;

$errorColor: #dc3545;
$text: #58585b;
$textMuted: #9e9ea2;

$cardShadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
